import { Dispatch, GetState } from 'store';
import { ACTION_DELETE_REPORT, ACTION_GET_REPORTS, ACTION_SET_REPORTS } from 'store/reports';
import { ASSISTANT_URL } from 'utils/constants';
import request, { emit } from 'utils/request';
import { ReportCreateModel, ReportSaveModel } from 'utils/types';


export const getReports = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()

    dispatch({ type: ACTION_GET_REPORTS, payload: null })

    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/reports/templates`);

    dispatch({ type: ACTION_SET_REPORTS, payload: res.data })
}

export const createReport = (report: ReportCreateModel) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState();
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/reports/templates`, report)

        return res.data;

    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const generateReport = (reportId: number, notes?: string) => async (dispatch: Dispatch, getState: GetState) => {
    try{
        const storeState = getState();

        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/reports/templates/${reportId}/revisions`, { notes })
        return res.data;

    } catch(err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const updateReport = (reportTemplateId: number, reportId: number, report: ReportSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    const res = await request.put(`/workspaces/${storeState.user.workspace.id}/reports/templates/${reportTemplateId}/revisions/${reportId}`, report);

    return res.data
}

export const deleteReport = (reportId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    await request.delete(`/workspaces/${storeState.user.workspace.id}/reports/templates/${reportId}`,);

    dispatch({ type: ACTION_DELETE_REPORT, payload: reportId });
}

export const generateSummary = (reportId: number, onResponse: (value: string) => void, onError: (error: Error) => void) => (dispatch: Dispatch, getState: GetState) =>  {
    const storeState = getState();
    emit('generate_report', {
        templateId: reportId,
        workspaceId: storeState.user.workspace.id,
        Authorization: `Bearer ${storeState.user.token}`
    },
    onResponse,
    onError);
}