import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dispatch, GetState, RootState } from 'store';
import { ASSISTANT_URL } from 'utils/constants';
import request, { emit } from 'utils/request';
import { AMAResponseTypes, AMAUserFeedback } from 'utils/types';

interface SendFeedbackParams {
    userId: number,
    sessionId: string,
    messageId: number,
    body: AMAUserFeedback
}

export const sendMessage = (
    query: string,
    sessionId: string,
    onResponse: (value: string, type?: AMAResponseTypes) => void,
    onError: (error: Error) => void
) => (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();

    emit(
        'message',
        {
            prompt: query,
            sessionId,
            workspaceId: storeState.user.workspace.id,
            Authorization: `Bearer ${storeState.user.token}`
        },
        onResponse,
        onError
    );
}

export const getUserLatestSession = createAsyncThunk(
    'assistant/getUserLatestSession',
    async (userId: number, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/users/${userId}/assistant/sessions/latest`;
        const response = await request.get(url, { baseURL: `${ASSISTANT_URL}api/v1` });

        return response.data;
    }
);

export const getUserSessions = createAsyncThunk(
    'assistant/getUserSessions',
    async (userId: number, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/users/${userId}/assistant/sessions`;
        const response = await request.get(url, { baseURL: `${ASSISTANT_URL}api/v1` });

        return response.data;
    }
);

export const getUserSession = createAsyncThunk(
    'assistant/getUserSession',
    async ({ userId, sessionId }: { userId: number, sessionId: string }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/users/${userId}/assistant/sessions/${sessionId}`;
        const response = await request.get(url, { baseURL: `${ASSISTANT_URL}api/v1` });

        return response.data;
    }
);

export const deleteUserSession = createAsyncThunk(
    'assistant/deleteUserSession',
    async ({ userId, sessionId }: { userId: number, sessionId: string }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/users/${userId}/assistant/sessions/${sessionId}`;
        const response = await request.delete(url, { baseURL: `${ASSISTANT_URL}api/v1` });

        return response.data;
    }
);

export const renameUserSession = createAsyncThunk(
    'assistant/renameUserSession',
    async ({ userId, sessionId, data }: { userId: number, sessionId: string, data: { title: string } }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/users/${userId}/assistant/sessions/${sessionId}`;
        const response = await request.patch(url, data, { baseURL: `${ASSISTANT_URL}api/v1` });

        return response.data;
    }
);

export const sendFeedback = createAsyncThunk(
    'assistant/sendFeedback',
    async ({ userId, sessionId, messageId, body }: SendFeedbackParams, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/users/${userId}/assistant/sessions/${sessionId}/messages/${messageId}/feedback`;
        const response = await request.post(url, body, { baseURL: `${ASSISTANT_URL}api/v1` });

        return response.data;
    }
);


export const getKBItemFromAMA = createAsyncThunk(
    'assistant/getKBItemFromAMA',
    async ({ kbId, data }: { kbId: number, data: { items: number[] } }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/kb/${kbId}/items`;
        const response = await request.post(url, data);

        return response.data;
    }
);