import { useState, useCallback, useEffect, FC, RefObject } from 'react';
import styles from './styles.module.scss'
import classNames from 'classnames/bind';

const classes = classNames.bind(styles);

interface ResizableDividerProps {
  setLeftWidth: (width: number) => void;
  containerRef: RefObject<HTMLDivElement>;
}

const ResizableDivider: FC<ResizableDividerProps> = ({ setLeftWidth, containerRef }) => {
  const [isDragging, setIsDragging] = useState(false);

  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    mouseDownEvent.preventDefault();
    setIsDragging(true);
  }, []);

  const stopResizing = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging]);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isDragging && containerRef.current) {
        const gridWidth = containerRef.current.offsetWidth;
        const offsetX = mouseMoveEvent.clientX - (window.innerWidth - gridWidth);

        const newLeftWidth = (offsetX / gridWidth) * 100;
        setLeftWidth(newLeftWidth);
      }
    },
    [isDragging, setLeftWidth, containerRef]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);

    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return <div className={classes('divider')} onMouseDown={startResizing}></div>;
};

export default ResizableDivider;