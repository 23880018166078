import { Dispatch, GetState } from 'store';
import { ACTION_ADD_INITIATIVES, ACTION_DELETE_INITIATIVE, ACTION_GET_INITIATIVES, ACTION_SET_INITIATIVES } from 'store/initiatives';
import request, { emit } from 'utils/request';
import { Initiative, InitiativeSaveModel, TeamResource } from 'utils/types';

export const getInitiatives = () => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    dispatch({ type: ACTION_GET_INITIATIVES, payload: null })
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/initiatives`);

    dispatch({ type: ACTION_SET_INITIATIVES, payload: res.data })

}

export const deleteInitiative = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.delete(`/workspaces/${storeState.user.workspace.id}/initiatives/${id}`)

        dispatch({ type: ACTION_DELETE_INITIATIVE, payload: id })

        return res;
    } catch (err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const createInitiative = (initiative: InitiativeSaveModel) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.post(`/workspaces/${storeState.user.workspace.id}/initiatives`, initiative)
        if (res.data) {
            dispatch({ type: ACTION_ADD_INITIATIVES, payload: res.data })
        }
        return res.data;

    } catch (err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const editInitiative = (initiative: InitiativeSaveModel, initiativeId: number) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.put(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}`, initiative)

        return res;

    } catch (err: any) {
        console.log(err);
        return { error: err.message }
    }

}

export const getInitiativeById = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    try {
        const storeState = getState();
        const res = await request.get(`/workspaces/${storeState.user.workspace.id}/initiatives/${id}`)

        return res.data as Initiative;
    } catch (err: any) {
        return { error: err }
    }
}

export const moveInitiativeToLive = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    await request.put(`/workspaces/${storeState.user.workspace.id}/initiatives/${id}/move-to-live`)
}

export const generateInitiative = (initiativeId: number, idea: string, onResponse: (value: string) => void, onError: (error: Error) => void, knowledgeContext = false) => (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    emit('generate_prd',
        {
            initiativeId,
            kb_context: knowledgeContext,
            workspaceId: storeState.user.workspace.id,
            Authorization: `Bearer ${storeState.user.token}`,
            prompt: idea
        },
        onResponse,
        onError);

}


export const generateRequirement = (initiativeId: number, requirementId: number, idea: string, onResponse: (value: string) => void, onError: (error: Error) => void, knowledgeContext = false) => (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    emit('generate_story',
        {
            initiativeId,
            kb_context: knowledgeContext,
            storyId: requirementId,
            workspaceId: storeState.user.workspace.id,
            Authorization: `Bearer ${storeState.user.token}`,
            prompt: idea
        },
        onResponse,
        onError);

}
export const getTeamResources = (initiativeId: number, teamId: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    const res = await request.get(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/teams/${teamId}/resources`)
    console.log(res.data);

    return res.data as TeamResource;
}

export const duplicateInitiative = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState();
    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/initiatives/${id}/duplicate`)

    return res.data as Initiative
}

export const addTeamResource = (initiativeId: number, teamId: number, resource: TeamResource) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    const res = await request.post(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/teams/${teamId}/resources`, resource)
    return res.data as TeamResource;
}

export const editTeamResource = (initiativeId: number, teamId: number, resourceId: number, resource: TeamResource) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    await request.put(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/teams/${teamId}/resources/${resourceId}`, resource)

}

export const deleteTeamResource = (initiativeId: number, teamId: number, resourceId: number,) => async (dispatch: Dispatch, getState: GetState) => {
    const storeState = getState()
    await request.delete(`/workspaces/${storeState.user.workspace.id}/initiatives/${initiativeId}/teams/${teamId}/resources/${resourceId}`)
}