import { forwardRef, useEffect, useImperativeHandle, useState, MouseEvent, ChangeEvent, useRef, useMemo } from 'react';
import { CircularProgress, Flexbox, ImageViewer } from 'components';
import classNamesBasic from 'classnames';
import classNames from 'classnames/bind';
import styles from './richTextEditor.module.scss';
const classes = classNames.bind(styles);
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { AttachmentMetadata, Block, CommentExtended, CommentFieldKey, User, aiGenerate } from 'utils/types';
import { UploadedFile } from 'components/FileUploader';
import { DeleteIcon, DocIcon, DriveFileIcon, PDFIcon } from 'components/icons';
import { deleteFile } from './index.api';
import { useDispatch } from 'react-redux';
import request, { emit } from 'utils/request';
import showdown from 'showdown';
import { AIStreamingEndCharCode, BASE_URL, fileFormats } from 'utils/constants';
const converter = new showdown.Converter();
import { useCommentEffects } from './hooks';
import store from 'store';
import { useWorkspaceId } from 'utils/hooks';

const attachIcon = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.58333 18.3307C8.31944 18.3307 7.23958 17.8967 6.34375 17.0286C5.44792 16.1606 5 15.0946 5 13.8307V4.8724C5 3.98351 5.31597 3.22656 5.94792 2.60156C6.57986 1.97656 7.34028 1.66406 8.22917 1.66406C9.13194 1.66406 9.89583 1.97656 10.5208 2.60156C11.1458 3.22656 11.4583 3.99045 11.4583 4.89323V13.1016C11.4583 13.6293 11.2778 14.0773 10.9167 14.4453C10.5556 14.8134 10.1111 14.9974 9.58333 14.9974C9.05556 14.9974 8.61111 14.7995 8.25 14.4036C7.88889 14.0078 7.70833 13.5391 7.70833 12.9974V4.83073H8.54167V13.0599C8.54167 13.3655 8.64236 13.6259 8.84375 13.8411C9.04514 14.0564 9.29167 14.1641 9.58333 14.1641C9.875 14.1641 10.1215 14.0599 10.3229 13.8516C10.5243 13.6432 10.625 13.3932 10.625 13.1016V4.8724C10.625 4.20573 10.3924 3.64323 9.92708 3.1849C9.46181 2.72656 8.89583 2.4974 8.22917 2.4974C7.5625 2.4974 6.99653 2.72656 6.53125 3.1849C6.06597 3.64323 5.83333 4.20573 5.83333 4.8724V13.8724C5.83333 14.8863 6.20139 15.7439 6.9375 16.4453C7.67361 17.1467 8.55556 17.4974 9.58333 17.4974C10.625 17.4974 11.5104 17.1432 12.2396 16.4349C12.9688 15.7266 13.3333 14.8585 13.3333 13.8307V4.83073H14.1667V13.8099C14.1667 15.0738 13.7188 16.1432 12.8229 17.0182C11.9271 17.8932 10.8472 18.3307 9.58333 18.3307Z" fill="currentColor"/></svg>'
const commentIcon = '<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.75 12.0738H11.1C11.3 12.0738 11.4708 12.0015 11.6125 11.857C11.7542 11.7124 11.825 11.5332 11.825 11.3195C11.825 11.1057 11.7531 10.928 11.6094 10.7863C11.4656 10.6447 11.2875 10.5738 11.075 10.5738H4.725C4.525 10.5738 4.35417 10.6461 4.2125 10.7907C4.07083 10.9353 4 11.1145 4 11.3282C4 11.542 4.07188 11.7197 4.21563 11.8613C4.35938 12.003 4.5375 12.0738 4.75 12.0738ZM4.75 8.82383H15.275C15.475 8.82383 15.6458 8.75154 15.7875 8.60695C15.9292 8.46235 16 8.28319 16 8.06945C16 7.8557 15.9281 7.67799 15.7844 7.53633C15.6406 7.39466 15.4625 7.32383 15.25 7.32383H4.725C4.525 7.32383 4.35417 7.39612 4.2125 7.5407C4.07083 7.6853 4 7.86447 4 8.0782C4 8.29195 4.07188 8.46966 4.21563 8.61133C4.35938 8.75299 4.5375 8.82383 4.75 8.82383ZM4.75 5.57383H15.275C15.475 5.57383 15.6458 5.50154 15.7875 5.35695C15.9292 5.21235 16 5.03319 16 4.81945C16 4.6057 15.9281 4.42799 15.7844 4.28633C15.6406 4.14466 15.4625 4.07383 15.25 4.07383H4.725C4.525 4.07383 4.35417 4.14612 4.2125 4.2907C4.07083 4.4353 4 4.61447 4 4.8282C4 5.04195 4.07188 5.21966 4.21563 5.36133C4.35938 5.503 4.5375 5.57383 4.75 5.57383ZM0 18.2488V1.54883C0 1.16549 0.15 0.819661 0.45 0.511328C0.75 0.202995 1.1 0.0488281 1.5 0.0488281H18.5C18.8833 0.0488281 19.2292 0.202995 19.5375 0.511328C19.8458 0.819661 20 1.16549 20 1.54883V14.5488C20 14.9322 19.8458 15.278 19.5375 15.5863C19.2292 15.8947 18.8833 16.0488 18.5 16.0488H4L1.275 18.7738C1.04167 19.0072 0.770833 19.06 0.4625 18.9324C0.154167 18.8048 0 18.577 0 18.2488ZM1.5 16.4238L3.375 14.5488H18.5V1.54883H1.5V16.4238Z" fill="#5A5A5A"/></svg>'
const aiIcon = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.3083 15.5001L11.6778 12.5215L14.6564 11.1521L11.6778 9.78259L10.3083 6.80399L8.93884 9.78259L5.96024 11.1521L8.93884 12.5215L10.3083 15.5001ZM10.3083 17.9213L8.19291 13.2674L3.53906 11.1521L8.19291 9.03666L10.3083 4.38281L12.4237 9.03666L17.0775 11.1521L12.4237 13.2674L10.3083 17.9213ZM17.0775 19.6136L16.0198 17.2867L13.6929 16.229L16.0198 15.1713L17.0775 12.8444L18.1352 15.1713L20.4622 16.229L18.1352 17.2867L17.0775 19.6136Z" fill="#353535"/></svg>';
const CHARCODEZWNBS = 65279;

export interface RichTextEditorProps {
    value?: string;
    user?: User;
    ownerId?: number,
    onChange: (newValue: string) => void;
    onBlur?: () => void;
    readOnly?: boolean;
    placeholder?: string;
    label?: string;
    required?: boolean;
    errorText?: string;
    fileUploadUrl?: string;
    commentsUrl?: string;
    files?: Block<AttachmentMetadata>[];
    onFileUpload?: (file: Block<AttachmentMetadata>) => void;
    onFileDelete?: (blockId: number) => void;
    comments?: CommentExtended[];
    onInit?: (editor: TinyMCEEditor) => void;
    onComment?: (commentId: number) => void;
    onCommentCreate?: (editor: TinyMCEEditor) => void;
    onCommentSuccess?: () => void;
    onCommentDelete?: (commentId: number) => void;
    onCommentResolve?: (commentId: number) => void;
    disabled?: boolean;
    insertButton?: boolean;
    className?: string;
    keepSelectionOnBlur?: boolean;
    labelIcon?: JSX.Element;
    editorMinHeight?: number;
    dataForAiGenerate?: aiGenerate;
    hasAiDialog?: boolean;
    commentFieldKey?: CommentFieldKey;
    commentObjectId?: number
}
export interface EditorRef {
    clearEditor: () => void;
    clearSelection: () => void;
    insertAtCurrentPosition: (text: string) => void;
    getEditor: () => TinyMCEEditor | null;
}

const init = (initialValue: string | undefined) => {
    if (initialValue) {
        // let parsedValue = jiraToDefaultMarkdown(initialValue)
        return converter.makeHtml(initialValue);
    }
    return '';
}

const isImage = (block: Block<AttachmentMetadata>) => {
    if (block.metadata.extension) {
        const extension = block.metadata.extension;
        if (extension.includes('doc') || extension.includes('docx') || extension.includes('csv')) {
            return false
        }
        if (extension.includes('pdf')) {
            return false
        }
    }
    return true
}

const openFile = (url?: string) => {
    if (url) {
        window.open(url, '_blank',)
    }
}

export default forwardRef<EditorRef, RichTextEditorProps>(({
    value,
    user,
    ownerId,
    onChange,
    onBlur,
    readOnly,
    label,
    required,
    errorText,
    placeholder,
    fileUploadUrl = '',
    files = [],
    onFileUpload,
    onFileDelete,
    disabled = false,
    comments = [],
    onInit,
    onCommentCreate,
    onCommentSuccess,
    insertButton = false,
    className,
    keepSelectionOnBlur,
    labelIcon,
    editorMinHeight,
    dataForAiGenerate,
    hasAiDialog,
    commentFieldKey,
    commentObjectId
}, textEditorRef) => {
    const [focused, setFocused] = useState(false);
    const [currentlyUploading, setCurrentlyUploading] = useState<UploadedFile[]>([]);
    const [imageViewerVisible, setImageViewerVisible] = useState(false);
    const [imageActiveIndex, setImageActiveIndex] = useState(0);
    const [images, setImages] = useState<Block<AttachmentMetadata>[]>([]);

    const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null);
    const [showComments, setShowComments] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const creatingComment = useRef(false);

    const blurTimer = useRef<NodeJS.Timeout | null>(null)
    const commentButton = useRef(true)

    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const token = store.getState().user.token;

    useEffect(() => {
        if (files.length) {
            setImages(files.filter(file => isImage(file)))
        }
    }, [files])

    useEffect(() => {
        if (editorRef.current) {
            const body = editorRef.current.getBody();
            body.style.caretColor = 'transparent'
        }
    }, [readOnly])

    const getImage = (block: Block<AttachmentMetadata>) => {
        if (block.metadata.extension) {
            const extension = block.metadata.extension;

            if (fileFormats.docs.some(f => f === extension)) {
                return (
                    <Flexbox className={classes('file')}>
                        <Flexbox className={classes('iconBox')}>
                            <DocIcon onClick={() => openFile(block.metadata.signedUrl)} className={classes('googleDoc', 'icon')} />
                        </Flexbox>
                        <Flexbox className={classes('overlay')}>
                            <DeleteIcon onClick={(e) => removeFile(e, block.id)} className={classes('resourcesCloseIcon')} />
                        </Flexbox>
                    </Flexbox>
                )
            }
            if (fileFormats.pdfs.some(f => f === extension)) {
                return (
                    <Flexbox className={classes('file')}>
                        <Flexbox className={classes('iconBox')}>
                            <PDFIcon onClick={() => openFile(block.metadata.signedUrl)} className={classes('pdf', 'icon')} />
                        </Flexbox>
                        <Flexbox className={classes('overlay')}>
                            <DeleteIcon onClick={(e) => removeFile(e, block.id)} className={classes('resourcesCloseIcon')} />
                        </Flexbox>
                    </Flexbox>
                )
            }
            if (fileFormats.images.some(f => f === extension)) {
                return (
                    <Flexbox onClick={() => openImageViewer(block.id)} className={classes('file')} style={{ backgroundImage: `url(${block.metadata.signedUrl})` }}>
                        <Flexbox className={classes('overlay')}>
                            <DeleteIcon onClick={(e) => removeFile(e, block.id)} className={classes('resourcesCloseIcon')} />
                        </Flexbox>
                    </Flexbox>
                )
            }
        }
        return (
            <Flexbox className={classes('file')}>
                <Flexbox className={classes('iconBox')}>
                    <DriveFileIcon onClick={() => openFile(block.metadata.signedUrl)} className={classes('fileIcon', 'icon')} />
                </Flexbox>
                <Flexbox className={classes('overlay')}>
                    <DeleteIcon onClick={(e) => removeFile(e, block.id)} className={classes('resourcesCloseIcon')} />
                </Flexbox>
            </Flexbox>
        )
    }

    const onValueChange = (newValue: string) => {
        if (!readOnly || creatingComment.current) {
            const trimmedText = newValue.trim().replace(/\u200B/g, '');
            onChange(trimmedText)
        }
    }

    const clearEditor = () => {
        onChange('')
    }

    const clearSelection = () => {
        if (editorRef.current) {
            const selectedBlocks = editorRef.current.selection.getSelectedBlocks();

            const selection = editorRef.current.selection.getSel();

            editorRef.current.selection.setContent('');

            if (selectedBlocks.length > 0 && selectedBlocks[0].nodeName === 'LI' && selection) {
                const node = selectedBlocks[0];

                if (node && node.lastChild) {
                    editorRef.current.selection.setCursorLocation(node.lastChild, node.lastChild.textContent ? node.lastChild?.textContent.length : 0)
                }
            }
        }
    }

    const insertAtCurrentPosition = (text: string) => {
        if (editorRef.current) {
            editorRef.current.selection.setContent(text);
        }
    }

    const getEditor = () => {
        return editorRef.current;
    }

    useImperativeHandle(textEditorRef, () => ({ clearEditor, clearSelection, insertAtCurrentPosition, getEditor }),)

    const onUploadStart = (files: UploadedFile[]) => {
        setCurrentlyUploading(prevValue => [...prevValue, ...files])
    }

    const onUploadProgress = (file: File, e: ProgressEvent) => {
        const status = Math.round(e.loaded * 100 / e.total);
        const name = file.name;
        setCurrentlyUploading(prevValue => {
            const file = prevValue.find(f => f.name === name);
            if (file) {
                file.status = status;
            }
            return [...prevValue];
        })
    }

    const onUploadFinish = (newFile: Block<AttachmentMetadata>) => {
        setCurrentlyUploading(prevData => {
            return prevData.filter(file => file.name !== newFile.metadata.fileName)
        })
        if (onFileUpload) {
            onFileUpload(newFile)
        }
    }

    const removeFile = async (e: MouseEvent<SVGSVGElement>, blockId: number) => {
        e.stopPropagation();

        await dispatch(deleteFile(blockId, fileUploadUrl));
        if (onFileDelete) {
            onFileDelete(blockId)
        }
    }

    const openImageViewer = (blockId: number) => {
        const index = images.findIndex(image => image.id === blockId);
        if (index >= 0) {
            setImageActiveIndex(index);
        } else {
            setImageActiveIndex(0);
        }
        setImageViewerVisible(true);
    }

    const onCloseImageViewer = () => {
        setImageViewerVisible(false);
    }

    const openFilePicker = () => {
        if (fileInputRef.current) {

            fileInputRef.current.click();
        }
    }

    const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        if (fileInputRef.current && fileInputRef.current.files) {
            const files = fileInputRef.current.files;
            const uploadingFiles: UploadedFile[] = [];
            for (let i = 0; i < files.length; i++) {

                const file = files[i];
                const f: UploadedFile = {
                    extension: file.name.split('.').pop() || '',
                    name: file.name,
                    url: URL.createObjectURL(file),
                }
                uploadingFiles.push(f);

                const uploadUrl = `/workspaces/${store.getState().user.workspace.id}/${fileUploadUrl}/attachment`.replaceAll('//', '/')
                request.uploadFile(uploadUrl, file, onUploadProgress).then((res) => {

                    onUploadFinish(res.data);
                }).catch(err => {

                })
            }
            onUploadStart(uploadingFiles)
            fileInputRef.current.value = ''
        }
    }

    const showCommentsSidebar = () => {
        setShowComments(true);
    }

    const hideCommentsSidebar = () => {
        setShowComments(false);
    }

    const blurEditor = () => {
        if (blurTimer.current) {
            clearTimeout(blurTimer.current);
        }
        blurTimer.current = setTimeout(() => {
            setFocused(false);

            if (editorRef.current && !keepSelectionOnBlur) {
                editorRef.current.selection?.collapse()
            }
            hideCommentsSidebar()
            if (onBlur && !readOnly) {
                onBlur();
            }
        }, 100)
    }

    const focusEditor = () => {
        setTimeout(() => { // needs this timeout for safari
            if (blurTimer.current) { clearTimeout(blurTimer.current) }
            setFocused(true)
        }, 0)

    }

    useCommentEffects({ editor: editorRef.current, comments, fieldKey: commentFieldKey, objectId: commentObjectId, onValueChange, onSuccess: onCommentSuccess });

    const aiAssistantResult: any = useMemo(
        () => ({
            title: 'AI assistant',
            body: {
                type: 'panel',
                items: [
                    {
                        name: 'preview',
                        type: 'iframe',
                        streamContent: true,
                        transparent: false,
                        sandboxed: false,
                    },
                ],
            },
            buttons: [
                {
                    type: 'cancel',
                    name: 'closeButton',
                    text: 'Cancel',
                },
                {
                    type: 'submit',
                    name: 'submitButton',
                    text: 'Insert',
                    buttonType: 'primary',
                },
            ],
            initialData: {
                preview: '',
            },
            onSubmit: (api: any) => {
                const data = api.getData();
                const text = data.preview;
                editorRef.current && editorRef.current.execCommand('mceInsertContent', false, text);
                api.close();
            },
        }),
        []
    );

    const aiAssistant: any = useMemo(
        () => ({
            title: 'AI Assistant',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'prompt',
                        placeholder: 'Ask AI to edit or generate',
                    },
                    {
                        type: 'checkbox',
                        name: 'useKB',
                        label: 'Use knowledge base',
                    },
                ],
            },
            buttons: [
                {
                    type: 'cancel',
                    name: 'closeButton',
                    text: 'Cancel',
                },
                {
                    type: 'submit',
                    name: 'submitButton',
                    text: 'Generate',
                    buttonType: 'primary',
                },
            ],
            initialData: {
                prompt: '',
                useKB: true,
                preview: '',
            },
            onSubmit: (api: any) => {
                const data = api.getData();

                api.redial(aiAssistantResult);
                api.unblock();

                let selectedContent = editorRef.current?.selection.getContent() ?? '';
                const allContent = editorRef.current?.getContent({ format: 'html' }) || '';

                const onError = (error: any) => {
                    api.unblock();
                };

                api.block('Please wait...');

                let messageData = '';

                let timeoutId: NodeJS.Timeout | null = null;
                const onMessage = (message: string) => {
                    if (timeoutId === null) {
                        timeoutId = setTimeout(() => {
                            api.setData({
                                preview: messageData,
                            });

                            timeoutId = null;

                            if (messageData.charCodeAt(messageData.length - 1) === AIStreamingEndCharCode) {
                                api.unblock();
                                messageData = ''
                            }
                        }, 500)
                    }

                    messageData += message;
                };
                if (allContent.length > 0) {
                    emit(
                        'refine_text',
                        {
                            Authorization: `Bearer ${token}`,
                            workspaceId: workspaceId,
                            prompt: data.prompt,
                            full_text: allContent,
                            selected_text: selectedContent,
                            kb_context: data.useKB,
                        },
                        onMessage,
                        onError
                    );
                } else if (dataForAiGenerate) {
                    emit(
                        dataForAiGenerate.name,
                        {
                            Authorization: `Bearer ${token}`,
                            workspaceId: workspaceId,
                            prompt: data.prompt,
                            kb_context: data.useKB,

                            initiativeId: dataForAiGenerate?.initiativeId,
                            component: dataForAiGenerate?.component,

                            storyId: dataForAiGenerate?.storyId,
                            content_only: dataForAiGenerate?.content_only,
                        },
                        onMessage,
                        onError
                    );
                }
            },
        }),
        [aiAssistantResult, workspaceId, token]
    );

    return (
        <Flexbox tabIndex={-1} className={classes('richTextEditorMainContainer', className)} vertical fullWidth>
            {label && <Flexbox className={classes('label')}>
                <Flexbox>
                    {label}
                    {required && <Flexbox className={classes('required')}>*</Flexbox>}
                </Flexbox>
                {labelIcon}
            </Flexbox>
            }
            <Flexbox className={classes('richTextEditorContainer', 'filled', { focused, readOnly, error: errorText })} fullWidth vertical>
                <Editor
                    apiKey='q099l65nk1qbtwec8k3hdensbsl9kn1kebq3udfmc2ch1lqj'

                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        onInit?.(editor);
                    }}
                    onFocusIn={focusEditor}
                    onSelectionChange={async (event, editor) => {
                        if (!focused) {
                            return
                        }
                        let node = editor.selection.getNode();

                        function findParentUntil(element: HTMLElement) {
                            let node = element;
                            while (node) {
                                if (node.className?.includes('highLight')) {
                                    return node;
                                }
                                node = node.parentNode as HTMLElement;
                            }
                            return null;
                        }

                        let parent = findParentUntil(node);
                        let messageId: number | null = null;
                        if (parent) {
                            node = parent
                            const id = node.getAttribute('data-id');
                            if (id) {
                                messageId = parseInt(id);
                            }

                            const selection = editor.selection.getSel();
                            if (selection?.type === 'Caret') {
                                const node = selection.anchorNode;
                                const el = node?.parentNode;
                                if (el && el.parentNode) {

                                    if (node?.textContent?.length === selection.anchorOffset) {
                                        if (!el.nextSibling || !el.nextSibling.textContent) {
                                            el.parentNode.append(String.fromCharCode(CHARCODEZWNBS));
                                        } else if (el.nextSibling.textContent.charCodeAt(0) !== CHARCODEZWNBS) {
                                            el.nextSibling.before(String.fromCharCode(CHARCODEZWNBS))
                                        }
                                        editor.selection.setCursorLocation(el.nextSibling!, 1)
                                    }
                                }
                            }
                        }
                        setSelectedCommentId(messageId);

                        if (messageId) {
                            showCommentsSidebar();
                        } else {
                            hideCommentsSidebar();
                        }

                        const content = editor.selection.getContent({ format: 'html' });
                        if (content.includes('highLight') || node.className.includes('highLight')) {
                            commentButton.current = false;
                        } else {
                            commentButton.current = true;
                        }
                    }}
                    onBlur={blurEditor}
                    onEditorChange={onValueChange}
                    onKeyDown={(e, editor) => {
                        if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
                            const selection: Selection | null = editor.selection.getSel();
                            if (selection && selection.anchorOffset === 1) {
                                const node = selection.anchorNode;
                                const offset = selection.anchorOffset;
                                if (node && node.textContent && node.textContent.charCodeAt(0) === CHARCODEZWNBS) {
                                    const prev = node.previousSibling;
                                    if (prev && prev.textContent) {
                                        if (e.key === 'Backspace') {

                                            prev.textContent = prev.textContent.substring(0, prev.textContent.length - 1)
                                        } else {
                                            if (prev.lastChild) {
                                                editor.selection.setCursorLocation(prev.lastChild, prev.textContent.length)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }}
                    value={init(value)}
                    init={{
                        body_class: `${disabled ? 'disabledBodyClass' : 'bodyClass'}`,
                        autoresize_bottom_margin: 0,
                        menubar: false,
                        branding: false,
                        resize: false,
                        height: editorMinHeight || 124,
                        min_height: editorMinHeight || 124,
                        statusbar: false,
                        placeholder: placeholder,
                        browser_spellcheck: true,
                        valid_elements: '*[*]',  // Allow all elements and attributes

                        plugins: [
                            'autoresize',
                            'advlist',
                            'autolink',
                            'lists',
                            'link',
                            'image',
                            'anchor',
                            'table',
                            'pageembed',
                            'media',
                            'mediaembed',
                        ],
                        link_context_toolbar: true,
                        relative_urls: false,
                        remove_script_host: false,
                        document_base_url: window.location.origin,
                        toolbar: classNamesBasic({
                            'blocks bold italic underline strikethrough bullist numlist link forecolor table': !disabled && !readOnly,
                            'customInsertButton': !disabled && !readOnly && insertButton,
                            'aiDialog': !disabled && !readOnly && hasAiDialog
                        }),
                        toolbar_sticky: true,
                        ui_mode: 'split',
                        toolbar_location: 'bottom',
                        contextmenu: 'table',
                        tinycomments_mode: 'embedded',
                        quickbars_selection_toolbar: 'customshowcomment',
                        quickbars_insert_toolbar: false,
                        font_css: '/rtfFonts.css',
                        content_css: '/rtfStyle.css',
                        content_style: `body { ${readOnly ? 'caret-color: transparent;' : ''}  }`,
                        mediaembed_service_url: `${BASE_URL}/workspaces/2/integrations/jira`,
                        remove_trailing_brs: false,
                        setup: (editor) => {
                            //To add a simple triangle icon:
                            editor.ui.registry.addIcon('attachFile', attachIcon);
                            editor.ui.registry.addIcon('commentIcon', commentIcon);
                            editor.ui.registry.addIcon('aiIcon', aiIcon);
                            editor.ui.registry.addButton('customInsertButton', {
                                // text: 'My Button',
                                icon: 'attachFile',
                                onAction: openFilePicker
                            });

                            if (user) {
                                editor.ui.registry.addToggleButton('customshowcomment', {
                                    text: 'Comment',
                                    icon: 'commentIcon',
                                    onSetup: (api) => {
                                        const handler = () => {
                                            let selection = editorRef.current?.selection.getContent() ?? '';
                                            const didSelectText = selection.trim().length > 0;
                                            api.setEnabled(didSelectText);
                                        }
                                        editor.on('selectionchange', handler);
                                        return () => {
                                            editor.off('selectionchange', handler)
                                        }
                                    },
                                    onAction: (api) => {
                                        if (commentButton.current) {
                                            onCommentCreate?.(editor);
                                        }
                                    }
                                });
                            }

                            if (hasAiDialog) {
                                editor.ui.registry.addButton('aiDialog', {
                                    text: 'AI assistant',
                                    icon: 'aiIcon',
                                    onAction: () => editor.windowManager.open(aiAssistant, { inline: 'toolbar', persistent: true, })
                                });
                            }

                            editor.ui.registry.addContextToolbar('commentstoolbar', {
                                predicate: () => {

                                    const selection = editor.selection.getSel();
                                    if (selection?.type === 'Range') {
                                        return true
                                    }
                                    return false
                                },
                                items: `${user ? 'customshowcomment' : ''}${hasAiDialog && !disabled && !readOnly ? ' aiDialog' : ''}`,
                                position: 'selection',
                                scope: 'node',
                            });

                        },

                        init_instance_callback: function (editor) {
                            // Add event listener for inserting new column
                            editor.on('NewCell', function (e) {
                                const table = editor.dom.getParent(editor.selection.getStart(), 'table');

                                if (table) {
                                    const cols = table.querySelectorAll('colgroup col');
                                    const colWidth = (100 / cols.length) + '%';

                                    cols.forEach(function (col) {
                                        (col as HTMLElement).style.width = colWidth;
                                    });
                                }
                            });
                        },
                    }}
                    disabled={disabled}
                    onKeyPress={readOnly ? () => false : undefined}
                />
                {!!files.length && (
                    <Flexbox className={classes('filesContainer')} fullWidth vertical >
                        <Flexbox className={classes('filesList')} fullWidth>
                            {files.map(file => {
                                return getImage(file)
                            })}
                            {
                                currentlyUploading.map(file => {
                                    return (
                                        <Flexbox align justify className={classes('circularProgress')}>
                                            <CircularProgress value={file.status || 0} />
                                        </Flexbox>
                                    )
                                })
                            }
                        </Flexbox>
                    </Flexbox>
                )}
                <input onChange={onFileSelect} multiple={true} type='file' ref={fileInputRef} className={classes('fileInput')} />
            </Flexbox>
            {
                errorText && <Flexbox className={classes('error')}>{errorText}</Flexbox>
            }

            <ImageViewer
                imageViewerVisible={imageViewerVisible}
                onClose={onCloseImageViewer}
                viewerImages={images.map(file => ({ alt: file.metadata.fileName || '', src: file.metadata.signedUrl || '', downloadUrl: file.metadata.signedUrl || '' }))}
                imageActiveIndex={imageActiveIndex}
            />
        </Flexbox>

    )
})
