import { Box, CommonButton, CustomTyphography, Flexbox } from 'components'
import { ContentCopyIcon, Stars } from 'components/icons'
import PRDPreviewItem from '../PRDPreviewItem'
import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { AIAnswerTypes, PRDMainType } from 'utils/types';
import { returnMainContent } from '../..';

const classes = classNames.bind(styles);

interface PRDPreviewPanelProps {
    messageType?: AIAnswerTypes,
    content?: PRDMainType,
    currentSection?: string,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string,
        type: 'prd'
    }) => void,
    manual?: boolean
}

const PRDPreviewPanel: FC<PRDPreviewPanelProps> = ({ content, setAiRefineDialog, messageType, currentSection, manual }) => {
    const prdPreviewRef = useRef<HTMLDivElement>(null);
    const [isCopied, setIsCopied] = useState(false)

    const openAiRefineDialog = (key: string) => {
        setAiRefineDialog({
            open: true,
            field: key,
            type: 'prd',
        })
    }

    const handleCopyPRD = () => {
        const contentElement = document.querySelector('#copy_content');

        if (!contentElement) {
            console.error('Content element not found');
            return;
        }

        const elementsToExclude = contentElement.querySelectorAll<HTMLElement>('[data-copy="false"]');
        elementsToExclude.forEach(el => el.style.display = 'none');

        const range = document.createRange();
        range.selectNodeContents(contentElement);
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
            try {
                document.execCommand('copy');
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 1000)
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }

            selection.removeAllRanges();
        }

        elementsToExclude.forEach(el => el.style.display = '');
    };

    useEffect(() => {
        if (prdPreviewRef.current && !manual) {
            if (messageType === AIAnswerTypes.PRD_PREVIEW_STREAM) {
                prdPreviewRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            } else if (messageType === AIAnswerTypes.PRD_PREVIEW && !currentSection) {
                prdPreviewRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, [messageType, currentSection, content, prdPreviewRef.current, manual]);

    return (
        <Box className={classes('sidePanel-mainContent')} >
            <CommonButton
                buttonType='shadow'
                data-copy='false'
                buttonSize='small'
                className={classes('copyIcon', 'mb-2')}
                onClick={handleCopyPRD}
            >
                {isCopied ? 'Copied!' : currentSection ? `Copy ${currentSection}` : 'Copy PRD'}
                <ContentCopyIcon />
            </CommonButton>
            <Flexbox fullWidth vertical className={classes('gap-4')} ref={prdPreviewRef}>
                {content && Object.entries(returnMainContent(content, currentSection)).map(([key, value]) => (
                    <Flexbox key={key} vertical className={classes('gap-2')}>
                        <Flexbox align justifyBetween>
                            <Flexbox className={classes('prdFieldLabel')}>{key}</Flexbox>
                            {
                                messageType === AIAnswerTypes.PRD_PREVIEW && (
                                    <Flexbox align
                                        className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                        onClick={() => openAiRefineDialog(key)}
                                        data-copy='false'
                                    >
                                        <Stars />
                                        <CustomTyphography>AI Refine</CustomTyphography>
                                    </Flexbox>
                                )
                            }
                        </Flexbox>
                        {
                            value !== null && <PRDPreviewItem value={value} />
                        }
                    </Flexbox>
                ))}
            </Flexbox>
        </Box>
    )
}

export default PRDPreviewPanel