
import { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLoadingSelector, userSelector } from 'store/user';
import { getMenuItemsArray } from 'common/mainLayout/Sidebar/menuItems';
import { getLoggedInUser, getPreferences } from 'pages/Signin/signin.api';
import MainLayout from 'common/mainLayout';
import { setToLocalStorage } from 'utils/localStorage';
import { REDIRECT_URL_KEY, documentDefaultTitle } from 'utils/constants';
import { Snackbar } from 'components';
import Flexbox from 'components/Flexbox';
import { ACTION_CLEAR_GLOBAL_ERROR, globalErrorSelector } from 'store/globalError';
import HandleIntegrations from 'pages/HandleIntegrations';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { useFirstAvailableMenuItem, useWorkspaceId } from 'utils/hooks';
import FeatureFlagEnabled from 'components/FeatureFlagEnabled';
mixpanel.init('3b7edd963b129a988c348cfe834e69b9', { debug: false, track_pageview: false, persistence: 'localStorage' });

if (process.env.REACT_APP_POSTHOG_API_KEY && process.env.REACT_APP_POSTHOG_API_HOST) {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
        api_host: process.env.REACT_APP_POSTHOG_API_HOST,
        debug: process.env.REACT_APP_IS_DEV === 'true',
        person_profiles: 'identified_only'
    });
}

const Login = lazy(() => import('./pages/Signin'));
const Signup = lazy(() => import('./pages/Signup'));
const CompleteSignup = lazy(() => import('./pages/Signup/completeSignup'))
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const user = useSelector(userSelector);
    const isUserLoading = useSelector(userLoadingSelector);
    const dispatch = useDispatch();
    const location = useLocation();

    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {

            if (user.token && user.accessToken && !user.id && !isUserLoading) {
                const getLogged: any = await dispatch(getLoggedInUser(user.token, user.accessToken));
                if (getLogged === 'not_completed_registration') {
                    return navigate('/complete-signup')
                }

            } else if (user.companyId) {
                await dispatch(getPreferences());

            }
        }
        getData();
    }, [user]);


    if (!user.token) {
        console.log('not authorized', location.pathname);

        setToLocalStorage(REDIRECT_URL_KEY, location.pathname);

        return (<Navigate replace to="/login" />)
    }

    return !user.id ? (<MainLayout />) : children
}

function App() {
    const menuItems = getMenuItemsArray();
    const workspaceId = useWorkspaceId();
    const firstAvailableMenuItem = useFirstAvailableMenuItem()

    const [showGlobalError, setShowGlobalError] = useState(false);

    const user = useSelector(userSelector);
    const location = useLocation();

    const envProduction = process.env.REACT_APP_IS_DEV === 'false'

    // const [getViews] = useLazyGetViewsQuery();
    // const views = useSelector(viewsSelector);

    // useEffect(() => {
    //     if (workspaceId) {
    //         getViews({ workspaceId })
    //     }
    // }, [workspaceId])

    useEffect(() => {
        // const items = views.length > 0 ? getMenuItemsArray(views) : getMenuItemsArray()
        if (user.id) {
            if (envProduction) {
                mixpanel.identify(user.email);
            }
            posthog.identify(user.email, {
                id: user.id,
                username: user.userName,
                fullName: user.fullName,
                companyId: user.companyId,
                role: user.roles[0].authority,
                workspaceId: user.workspace.id,
                workspaceName: user.workspace.name,
                env: process.env.REACT_APP_POSTHOG_ENVIRONMENT,
            });
        }
    }, [user])

    useEffect(() => {
        if (envProduction) {
            mixpanel.track_pageview({
                env: {
                    username: user.fullName,
                    workspace: user.workspace.name,
                }
            })
        }
    }, [location])

    useEffect(() => {
        TagManager.initialize({ gtmId: 'G-DLGT06JWGK' });
    }, []);

    useEffect(() => {
        const flattenedMenu = menuItems.flatMap((item) =>
            item.subMenus ? item.subMenus : item
        );

        const currentPage = flattenedMenu.find(page => !!page.url && location.pathname.includes(page.url))
        document.title = currentPage ? `${currentPage.title} - ${documentDefaultTitle}` : documentDefaultTitle;
    }, [location.pathname, workspaceId]);

    const globalError = useSelector(globalErrorSelector);

    const dispatch = useDispatch()

    useEffect(() => {
        if (globalError) {
            setShowGlobalError(true)
        }
    }, [globalError])

    const hideGlobalError = () => {
        setShowGlobalError(false);
        dispatch({ type: ACTION_CLEAR_GLOBAL_ERROR });
    }

    return (
        <Suspense fallback={<div></div>}>

            <Routes>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="complete-signup" element={<CompleteSignup />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="change-password" element={<RequireAuth><MainLayout><ChangePassword /></MainLayout></RequireAuth>} />
                <Route path="blank" element={<RequireAuth><MainLayout><HandleIntegrations /></MainLayout></RequireAuth>} />

                {menuItems.map(item => !item.hasPopupMenu && (
                    <>
                    {item.sections ? (item.sections && item.sections.map(section => (
                            <Route
                                key={section.url}
                                path={section.url}
                                element={
                                    <FeatureFlagEnabled fallback={<Navigate replace to={firstAvailableMenuItem?.url || ''} />} flagKey={item.featureFlag}>
                                        <RequireAuth>
                                            <MainLayout>
                                                <section.component />
                                            </MainLayout>
                                        </RequireAuth>
                                    </FeatureFlagEnabled>
                                }
                            />
                        )
                        )) : (<Route key={item.url} path={item.url}>
                        <Route path='' element={
                            <FeatureFlagEnabled fallback={<Navigate replace to={firstAvailableMenuItem?.url || ''} />} flagKey={item.featureFlag}>
                                <RequireAuth>
                                    <MainLayout>
                                        {<item.component />}
                                    </MainLayout>
                                </RequireAuth>
                            </FeatureFlagEnabled>
                        }
                        />
                        {item.nestedRoutes && item.nestedRoutes.map(route => (
                            <Route key={route.url} path={route.url} element={
                                <FeatureFlagEnabled fallback={<Navigate replace to={firstAvailableMenuItem?.url || ''} />} flagKey={item.featureFlag}>
                                    <RequireAuth>
                                        <MainLayout>
                                            <route.component />
                                        </MainLayout>
                                    </RequireAuth>
                                </FeatureFlagEnabled>
                            }
                            />
                        ))}
                        {item.subMenus && item.subMenus.map(route => !route.hasPopupMenu && (
                            <Route key={route.url} path={route.url} >
                                <Route path='' element={
                                    <FeatureFlagEnabled fallback={<Navigate replace to={firstAvailableMenuItem?.url || ''} />} flagKey={route.featureFlag}>
                                        <RequireAuth>
                                            <MainLayout>
                                                <route.component />
                                            </MainLayout>
                                        </RequireAuth>
                                    </FeatureFlagEnabled>
                                } />
                                {route.nestedRoutes && route.nestedRoutes.map(nestedRoute => (
                                    <Route key={nestedRoute.url} path={nestedRoute.url} element={
                                        <FeatureFlagEnabled fallback={<Navigate replace to={firstAvailableMenuItem?.url || ''} />} flagKey={item.featureFlag}>
                                            <RequireAuth>
                                                <MainLayout>
                                                    <nestedRoute.component />
                                                </MainLayout>
                                            </RequireAuth>
                                        </FeatureFlagEnabled>
                                    }
                                    />
                                ))}
                            </Route>
                        ))}


                    </Route>)}

                    </>
                )
                )}
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <Snackbar
                type='error'
                open={showGlobalError}
                onClose={hideGlobalError}
            >
                <Flexbox>{globalError}</Flexbox>
            </Snackbar>
        </Suspense>
    );
}

export default App;
