import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { Flexbox, Snackbar, TextArea, CustomTyphography, ConfirmationDialog, ActionsMenu, Loader, IconButton, Tooltip, CommonButton, Dialog, Box } from 'components';
import classNames from 'classnames/bind';
import styles from './assistant.module.scss';
import { ChatIcon, CloseIcon, DeleteIcon, EditIcon, Logo, MenuOpenIcon, PlusCircle, SendIcon } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSession, getKBItemFromAMA, getUserLatestSession, getUserSession, getUserSessions, renameUserSession, sendFeedback, sendMessage } from './assistant.api';
import { userSelector } from 'store/user';
import { v4 as uuid } from 'uuid';
import { currentSessionLoadingSelector, deleteSession, getRelevantReferences, messagesSelector, renameChat, replaceMessages, sessionIdSelector, sessionsSelector, updateMessages, setSessionId } from 'store/assistant-slice';
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from 'utils/localStorage';
import CommonInput from 'components/CommonInput';
import { AIAnswerTypes, AMAResponseTypes, AMAUserFeedback, AssistantMessage, AMAReference, Message, PRDMainType } from 'utils/types';
import { capitalize } from 'utils/string';
import PRDPreviewPanel from './components/MessageSidePanel/PRDPreviewPanel';
import FeedbackDialog from './components/FeedbackDialog';
import AMAMessage from './components/AMAMessage';
import AIRefineDialog from './components/AIRefineDialog';
import Markdown from 'components/Markdown';
import ResizableDivider from '../../components/ResizableDivider';
import MockupPanel from './components/MessageSidePanel/MockupPanel';
import MessageSidePanel from './components/MessageSidePanel';

const classes = classNames.bind(styles);

export const returnMainContent = (content: PRDMainType, currentSection?: string) => {
    let newContent = content

    if (content?.title) {
        const { title, ...restContent } = content;
        newContent = restContent
    }

    if (currentSection) {
        newContent = { [currentSection]: content[currentSection] }
    }

    return newContent
}

type MessageSidePanelBase = {
    open: boolean;
    id?: string | number;
    manual?: boolean;
    streamingId?: string | number;
};

type PRDPreviewSidePanel = MessageSidePanelBase & {
    content?: any | { [key: string]: string };
    messageType?: AIAnswerTypes.PRD_PREVIEW | AIAnswerTypes.PRD_PREVIEW_STREAM;
    currentSection?: string;
};

type MockupSidePanel = MessageSidePanelBase & {
    content?: any;
    messageType?: AIAnswerTypes.MOCKUP;
};

type MessageSidePanel =
    | PRDPreviewSidePanel
    | MockupSidePanel

export function isPRDPreviewSidePanel(panel: MessageSidePanel): panel is PRDPreviewSidePanel {
    return panel.messageType === AIAnswerTypes.PRD_PREVIEW || panel.messageType === AIAnswerTypes.PRD_PREVIEW_STREAM;
}

export function isMockupSidePanel(panel: MessageSidePanel): panel is MockupSidePanel {
    return panel.messageType === AIAnswerTypes.MOCKUP;
}

export default () => {
    const dispatch = useDispatch();
    const gridContainerRef = useRef<HTMLDivElement>(null);

    const [question, setQuestion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [assistantStatus, setAssistantStatus] = useState<null | string>(null);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openHistorySidebar, setOpenHistorySidebar] = useState(false);
    const [currentSessionId, setCurrentSessionId] = useState<null | string>(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [chatTitle, setChatTitle] = useState('');
    const [feedbackStatus, setFeedbackStatus] = useState<'initial' | 'pending' | 'success' | null>(null)
    const [feedback, setFeedback] = useState<AMAUserFeedback | null>(null)
    const [latestMessageId, setLatestMessageId] = useState<number | null>(null)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Sorry, An error occurred. Please try again later!');
    const [streamedText, setStreamedText] = useState('');
    const [aiRefineDialog, setAiRefineDialog] = useState<{ open: boolean, type?: 'prd' | 'mockup', field?: string }>({ open: false })
    const [refinedContent, setRefinedContent] = useState<string>('')
    const [leftWidth, setLeftWidth] = useState(50);

    const [messageSidePanel, setMessageSidePanel] = useState<PRDPreviewSidePanel | MockupSidePanel>({ open: false })

    const user = useSelector(userSelector);
    const sessionId = useSelector(sessionIdSelector);
    const messages = useSelector(messagesSelector);
    const sessions = useSelector(sessionsSelector);
    const currentSessionLoading = useSelector(currentSessionLoadingSelector);
    const lastSelectedSessionId = getFromLocalStorage('sessionId');

    useEffect(() => {
        if (!sessionId) {
            const newSessionId = uuid();
            dispatch(setSessionId(newSessionId))
        }
    }, [sessionId]);

    useEffect(() => {
        const handleLoad = () => {
            if (lastSelectedSessionId) {
                dispatch(getUserSession({ userId: user.id, sessionId: lastSelectedSessionId }))
            } else {
                dispatch(getUserLatestSession(user.id));
            }
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    useEffect(() => {
        dispatch(getUserSessions(user.id))
    }, [])

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const onSendMessage = async (isPRDRefine?: boolean, prompt?: string) => {
        if (sessionId) {
            setFeedbackStatus('initial');

            let prdStreamingValue: any = {}
            let userPrompt: string = question

            // Final message Values
            let refinement: boolean = false
            let answerType: AIAnswerTypes = AIAnswerTypes.TEXT
            let generatedText = '';
            let jsonResponse: any = {}
            let mockupStreamingValue: string = ''
            let references: AMAReference[] = []
            const newDiscussionId = uuid();

            if (isPRDRefine) {
                // Refined from PRD
                userPrompt = `Make the following changes to the field "${capitalize(aiRefineDialog.field || '')}":\n${capitalize(refinedContent)}`
            } else if (!!refinedContent) {
                // Refined from Mockup
                userPrompt = capitalize(refinedContent)
            } else if (!!prompt) {
                // Refine PRD Confirmation
                userPrompt = prompt
            }

            const newMessages: Message[] = [
                {
                    from: 'user',
                    text: userPrompt,
                    references: [],
                    id: newDiscussionId
                },
                ...messages
            ];

            dispatch(replaceMessages(newMessages));
            setQuestion('');
            setRefinedContent('')
            setIsLoading(true)
            setAiRefineDialog({ open: false })

            setMessageSidePanel(prev => ({
                ...prev,
                // Each time when user asks something we should reset manual value
                manual: false,
            }))

            dispatch(
                sendMessage(
                    userPrompt,
                    sessionId,
                    (value: any, type?: AMAResponseTypes) => {
                        if (type === AMAResponseTypes.LOG) {
                            setAssistantStatus(value as string)
                        } else if (type === AMAResponseTypes.METADATA) {
                            setLatestMessageId(value.message_id)
                            references = Array.isArray(value.references) ? getRelevantReferences(value.references) : []
                        } else if (type === AMAResponseTypes.FINISH) {
                            setIsLoading(false)
                            setAssistantStatus(null)

                            dispatch(updateMessages([
                                {
                                    id: newDiscussionId,
                                    from: 'assistant',
                                    text: {
                                        // This is a temporary solution, we should change this, when AI will save refine type
                                        type: answerType,
                                        refinement,
                                        content: answerType === AIAnswerTypes.PRD_PREVIEW ? jsonResponse : answerType === AIAnswerTypes.MOCKUP ? mockupStreamingValue : generatedText.trim().replace('```', '')
                                    },
                                    references,
                                }
                            ]));

                            setMessageSidePanel(prev => ({
                                ...prev,
                                streamingId: undefined,
                            }))

                            mockupStreamingValue = ''
                            setStreamedText('');
                            return;
                        } else {
                            setAssistantStatus(value.type === AIAnswerTypes.PRD_PREVIEW_STREAM ? 'Preparing the PRD...' : null);
                            let text = '';
                            refinement = value.refinement;
                            answerType = value.type

                            if (value.type === AIAnswerTypes.TEXT) {
                                text = value.content;
                            } else if (value.type === AIAnswerTypes.PRD_PREVIEW) {
                                jsonResponse = value.content

                                // Automatically open Side Panel when PRD is ready
                                setMessageSidePanel(prev => ({
                                    open: true,
                                    manual: prev.manual,
                                    messageType: AIAnswerTypes.PRD_PREVIEW,
                                    id: newDiscussionId,
                                    content: jsonResponse,
                                    currentSection: messageSidePanel.manual && isPRDPreviewSidePanel(prev) && prev.currentSection ? prev.currentSection : undefined,
                                }))

                                // Reset prdStreamingValue when PRD is Ready
                                prdStreamingValue = {}
                            } else if (value.type === AIAnswerTypes.PRD_PREVIEW_STREAM) {
                                setIsLoading(false)

                                const [key, contentValue] = Object.entries(value.content)[0];

                                prdStreamingValue = {
                                    ...prdStreamingValue,
                                    [key]: prdStreamingValue ? ((prdStreamingValue[key] || '') + contentValue).replace('```html', '') : contentValue
                                }

                                setMessageSidePanel(prev => {
                                    if (!prev.id || Object.keys(prdStreamingValue).some(key => key !== 'title') || (prev.id === newDiscussionId)) {

                                        return {
                                            open: true,
                                            id: newDiscussionId,
                                            manual: prev.manual,
                                            messageType: AIAnswerTypes.PRD_PREVIEW_STREAM,
                                            content: prdStreamingValue,
                                            currentSection: prev.manual && isPRDPreviewSidePanel(prev) ? prev.currentSection : key,
                                            streamingId: newDiscussionId,
                                        }
                                    } else {
                                        return prev
                                    }
                                })

                                const updatedMessages: Message[] = [
                                    {
                                        id: newDiscussionId,
                                        from: 'assistant',
                                        text: {
                                            type: AIAnswerTypes.PRD_PREVIEW_STREAM,
                                            content: prdStreamingValue,
                                            refinement: value.refinement
                                        },
                                        references,
                                    },
                                    ...newMessages
                                ];

                                dispatch(replaceMessages(updatedMessages));
                            } else if (value.type === AIAnswerTypes.MOCKUP) {
                                setIsLoading(false)
                                mockupStreamingValue = `${mockupStreamingValue}${value.content}`

                                setMessageSidePanel(prev => {
                                    // If side panel is closed,
                                    // or there is no mockupStreamingValue so it enters to this setState for the first time,
                                    // or the current panel and opened one are the same
                                    if (!prev.id || !mockupStreamingValue || (prev.id === newDiscussionId)) {
                                        return {
                                            open: true,
                                            id: newDiscussionId,
                                            manual: prev.manual,
                                            messageType: AIAnswerTypes.MOCKUP,
                                            streamingId: newDiscussionId,
                                            content: mockupStreamingValue,
                                        }
                                    } else {
                                        return {
                                            ...prev,
                                            streamingId: newDiscussionId,
                                        }
                                    }
                                })

                                const updatedMessages: Message[] = [
                                    {
                                        id: newDiscussionId,
                                        from: 'assistant',
                                        text: {
                                            type: AIAnswerTypes.MOCKUP,
                                            content: mockupStreamingValue,
                                            refinement: value.refinement
                                        },
                                        references,
                                    },
                                    ...newMessages
                                ];

                                dispatch(replaceMessages(updatedMessages));
                            }

                            generatedText = (generatedText + text).replace('```html', '')
                            setStreamedText(generatedText);
                        }
                    },
                    (error) => {
                        // Show appropriate Error message
                        if (error.message) {
                            setErrorMessage(error.message)
                        }

                        setStreamedText('');
                        setIsLoading(false)
                        setError(true)
                    }
                )
            );
        }
    };

    useEffect(() => {
        // Save Session if user did something in that session
        if (sessionId && (sessions.some(session => session.session_id === sessionId) || messages.length > 0)) {
            setToLocalStorage('sessionId', sessionId);
        }
    }, [sessionId, messages.length])

    const handleDeleteChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(deleteUserSession({ userId: user.id, sessionId: currentSessionId }))
                dispatch(deleteSession(currentSessionId));
                removeFromLocalStorage('sessionId');

                if (sessionId === currentSessionId) {
                    handleStartNewSession()
                }
            } catch (error) {
                console.log(error);
            }
        }
        setOpenDeleteConfirmation(false)
        setCurrentSessionId(null)
    }

    const showDeleteConfirmation = (sessionId: string) => {
        setCurrentSessionId(sessionId)
        setOpenDeleteConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleSelectSession = (sessionId: string) => {
        try {
            setMessageSidePanel({ open: false })
            dispatch(getUserSession({ userId: user.id, sessionId: sessionId }))
        } catch (error) {
            console.log(error);
        }
    }

    const handleStartNewSession = () => {
        const newSessionId = uuid();
        dispatch(setSessionId(newSessionId))
        dispatch(replaceMessages([]))
        setMessageSidePanel({ open: false })
    }

    const resetRenameDialog = () => {
        setOpenRenameDialog(false)
        setCurrentSessionId(null);
        setChatTitle('')
    }

    const handleRenameChat = async () => {
        if (currentSessionId) {
            try {
                await dispatch(renameUserSession({ userId: user.id, sessionId: currentSessionId, data: { title: chatTitle } }));
                dispatch(renameChat({ id: currentSessionId, title: chatTitle }))
            } catch (err) {
                console.log(err);
            }
        }

        resetRenameDialog()
    }

    const onResetFeedback = () => {
        setFeedbackStatus(null);
        setFeedback(null)
    }

    const handleSendFeedback = (feedbackType: 'positive' | 'negative') => {
        setFeedback({ type: feedbackType, content: '' })
        setFeedbackStatus('pending')
    }

    const handleSubmitFeedback = async () => {
        if (latestMessageId && sessionId && feedback) {
            try {
                await dispatch(sendFeedback({ userId: user.id, sessionId, messageId: latestMessageId, body: feedback }))
                setFeedbackStatus('success');
            } catch (err) {
                console.log(err);
            }

            setTimeout(() => {
                onResetFeedback()
            }, 1500);
        }
    }

    useEffect(() => {
        if (sessions.every(session => session.session_id !== sessionId) && messages.length >= 2) {
            dispatch(getUserSessions(user.id))
        }
    }, [messages.length, sessionId, sessions.length])

    useEffect(() => {
        if (chatContainerRef.current && streamedText) {
            chatContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streamedText, messages]);

    const onGetKBSource = async (kbId: number, kbItemIds: number[]) => {
        try {
            await dispatch(getKBItemFromAMA({ kbId, data: { items: kbItemIds } }))
        } catch (err) {
            console.log(err);
        }
    }

    const handleMessagePreview = (message: AssistantMessage, key?: string) => {
        setMessageSidePanel(prev => {
            let newPanel: MessageSidePanel = {
                open: true,
                id: message.id,
                manual: true,
                streamingId: prev.streamingId
            }

            if (key) {
                newPanel = {
                    ...newPanel,
                    messageType: message.text.type as (AIAnswerTypes.PRD_PREVIEW | AIAnswerTypes.PRD_PREVIEW_STREAM),
                    currentSection: key,
                    content: typeof message.text === 'object' && 'content' in message.text && typeof message.text.content === 'object' ? {
                        ...prev.content,
                        [key]: message.text.content[key]
                    } : {},
                    manual: message.text.type === AIAnswerTypes.PRD_PREVIEW_STREAM,
                }
            } else if (message.text.type === AIAnswerTypes.PRD_PREVIEW) {
                newPanel = {
                    ...newPanel,
                    messageType: AIAnswerTypes.PRD_PREVIEW,
                    content: (message.from === 'assistant' && 'content' in message.text ? message.text.content : undefined) as { [key: string]: string },
                }
            } else if (message.text.type === AIAnswerTypes.MOCKUP) {
                newPanel = {
                    ...newPanel,
                    messageType: AIAnswerTypes.MOCKUP,
                    content: message.text.content,
                }
            }

            return newPanel
        })
    }

    const handleRefineSection = () => {
        setAiRefineDialog(prev => ({ ...prev, open: true, type: 'prd' }))
    }

    const handleConfirmRefinedPRD = () => {
        onSendMessage(undefined, 'Show the full updated PRD')
    }

    return (
        <Flexbox fullWidth className={classes('assistantContainer')}>
            {
                openHistorySidebar && (
                    <Flexbox vertical className={classes('history', 'gap-3')}>
                        <CommonButton
                            buttonType='shadow'
                            variant='text'
                            startIcon={<PlusCircle />}
                            onClick={handleStartNewSession}
                        >
                            New Session
                        </CommonButton>
                        <Flexbox vertical className={classes('gap-3', 'sessions')}>
                            {
                                sessions.map(session => {
                                    return (
                                        <Flexbox
                                            justifyBetween
                                            key={session.session_id}
                                            className={classes('history-item', { active: session.session_id === sessionId })}
                                            onClick={() => handleSelectSession(session.session_id)}
                                        >
                                            <CustomTyphography className={classes('ellipsisText')}>{session.title}</CustomTyphography>
                                            <ActionsMenu className={classes('sessionActions-btn')}
                                                buttonItems={[
                                                    {
                                                        label: 'Rename chat',
                                                        action: () => {
                                                            setOpenRenameDialog(true);
                                                            setCurrentSessionId(session.session_id)
                                                            setChatTitle(session.title)
                                                        },
                                                        icon: <EditIcon />
                                                    },
                                                    { label: 'Delete chat', action: () => showDeleteConfirmation(session.session_id), icon: <DeleteIcon />, type: 'red' },
                                                ]}
                                            />
                                        </Flexbox>
                                    )
                                })
                            }
                        </Flexbox>
                    </Flexbox>
                )
            }
            <Box
                id="grid-container"
                ref={gridContainerRef}
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: messageSidePanel.open ? `${leftWidth}% auto 1fr` : '1fr'
                }}>
                <Flexbox className={classes('messengerContainer')} fullWidth vertical ref={chatContainerRef}>
                    <Flexbox className={classes('messagesContainer')} fullWidth>
                        {currentSessionLoading ? <Flexbox justify fullWidth className={classes('p-4')}><Loader /></Flexbox> : <>
                            {
                                (!!assistantStatus || isLoading) && (
                                    <Flexbox className={classes('messageContainer', { fromAI: streamedText.length })}>
                                        <Flexbox className={classes('logoContainer')}>
                                            <Logo />
                                        </Flexbox>
                                        <Flexbox>
                                            {streamedText?.length ? (
                                                <Flexbox
                                                    fullWidth
                                                    vertical
                                                >
                                                    <Markdown text={streamedText?.replaceAll('```html', '')?.replaceAll('```', '')} />
                                                </Flexbox>
                                            ) : (
                                                <Flexbox className={classes('gap-1')}>
                                                    {assistantStatus &&
                                                        <Flexbox align className={classes('assistantStatus')}>
                                                            <CustomTyphography className={classes('assistantStatus-message')}>{assistantStatus}</CustomTyphography>
                                                        </Flexbox>
                                                    }
                                                    <Flexbox align className={classes('dotFlashingContainer')}>
                                                        <Flexbox className={classes('dotFlashing')} />
                                                    </Flexbox>
                                                </Flexbox>
                                            )}
                                        </Flexbox>
                                    </Flexbox>
                                )
                            }

                            {messages.map((message: Message, index) => (
                                <AMAMessage
                                    key={index}
                                    message={message}
                                    showFeedbackConversation={message.from === 'assistant' && message.text.type !== AIAnswerTypes.PRD_PREVIEW_STREAM && message.text.type !== AIAnswerTypes.MOCKUP && index === 0 && !!feedbackStatus}
                                    feedbackStatus={feedbackStatus}
                                    handleRefine={handleRefineSection}
                                    handleConfirmRefinedPRD={handleConfirmRefinedPRD}
                                    handleSendFeedback={handleSendFeedback}
                                    closeFeedbackConversation={() => setFeedbackStatus(null)}
                                    prdTitle={(message.from === 'assistant' && (message.text.type === AIAnswerTypes.PRD_PREVIEW || message.text.type === AIAnswerTypes.PRD_PREVIEW_STREAM)) ? message.text.content?.title : undefined}
                                    onGetKBSource={onGetKBSource}
                                    handlePRDSectionPreview={handleMessagePreview}
                                    currentSection={isPRDPreviewSidePanel(messageSidePanel) ? messageSidePanel.currentSection : undefined}
                                    mockupStreaming={isMockupSidePanel(messageSidePanel) && message.id === messageSidePanel.streamingId}
                                    messageSidePanelType={messageSidePanel.messageType}
                                    messageSidePanelId={messageSidePanel.id}
                                    handleMessagePreview={() => handleMessagePreview(message as AssistantMessage)}
                                />
                            )
                            )}
                        </>
                        }
                    </Flexbox>

                    <Flexbox className={classes('newMessageContainer')} fullWidth>
                        <Flexbox align className={classes('gap-1')}>
                            <Tooltip title={`${openHistorySidebar ? 'Close' : 'Open'} Chat History`}>
                                <IconButton
                                    className={classes('action-btn')}
                                    disabled={!sessions.length}
                                    onClick={() => setOpenHistorySidebar(prev => !prev)}
                                >
                                    {openHistorySidebar ? <CloseIcon /> : <MenuOpenIcon />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'New Chat'}>
                                <IconButton
                                    className={classes('action-btn')}
                                    onClick={handleStartNewSession}
                                >
                                    <ChatIcon />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>

                        <TextArea
                            value={question}
                            onKeyDown={onKeyPress}
                            onChange={e => {
                                setQuestion(e.target.value);
                            }}
                            placeholder="Ask here"
                            className={classes('textArea')}
                            fullWidth
                            endAdornment={
                                <SendIcon className={classes('sendIcon')} onClick={() => onSendMessage()} />
                            }
                            autoFocus
                            disabled={
                                isLoading ||
                                !!messageSidePanel.streamingId
                            }
                        />
                    </Flexbox>
                    <ConfirmationDialog
                        open={openDeleteConfirmation}
                        onClose={onCancelDelete}
                        onConfirm={handleDeleteChat}
                        confirmButtonStyle='danger'
                        title='Delete the history?'
                    >
                        <Flexbox>
                            Are you sure you want to clear the chat history?
                        </Flexbox>
                    </ConfirmationDialog>

                    <Snackbar open={error} onClose={() => setError(false)} type="error">
                        <Flexbox>{errorMessage}</Flexbox>
                    </Snackbar>
                </Flexbox>

                {
                    messageSidePanel.open && <ResizableDivider containerRef={gridContainerRef} setLeftWidth={setLeftWidth} />
                }

                {
                    messageSidePanel.open && (
                        <MessageSidePanel
                            title={isMockupSidePanel(messageSidePanel) ? 'Mockup Preview' : messageSidePanel.content?.title ? messageSidePanel.content?.title : 'PRD Preview'}
                            onClose={() => setMessageSidePanel({ open: false })}
                        >
                            {isMockupSidePanel(messageSidePanel) ? <MockupPanel
                                content={messageSidePanel.content}
                                streaming={messageSidePanel.streamingId === messageSidePanel.id}
                                setAiRefineDialog={setAiRefineDialog}
                            /> : isPRDPreviewSidePanel(messageSidePanel) ? <PRDPreviewPanel
                                content={messageSidePanel.content}
                                currentSection={messageSidePanel.currentSection}
                                setAiRefineDialog={setAiRefineDialog}
                                messageType={messageSidePanel.messageType}
                                manual={messageSidePanel.manual}
                            /> : <></>}
                        </MessageSidePanel>
                    )
                }
            </Box>
            <Dialog
                onClose={resetRenameDialog}
                open={openRenameDialog}
                cancelButton
                title="Rename Chat"
                confirmButton
                onConfirm={handleRenameChat}
                disabled={!chatTitle}
                PaperProps={{
                    sx: {
                        width: '450px'
                    }
                }}
            >
                <Flexbox fullWidth>
                    <CommonInput value={chatTitle} onChange={(e) => setChatTitle(e.target.value)} />
                </Flexbox>
            </Dialog>


            {
                feedback && <FeedbackDialog
                    pendingStatus={feedbackStatus === 'pending'}
                    handleSubmitFeedback={handleSubmitFeedback}
                    feedback={feedback}
                    handleChangeFeedback={e => setFeedback({ type: feedback.type, content: e.target.value })}
                />
            }
            {
                aiRefineDialog.open && <AIRefineDialog
                    open={aiRefineDialog.open}
                    onClose={() => setAiRefineDialog({ open: false })}
                    onConfirm={() => onSendMessage(aiRefineDialog.type === 'prd' && !!aiRefineDialog.field)}
                    title={`Refining the ${capitalize(aiRefineDialog?.field || '')}`}
                    refinedContent={refinedContent}
                    setRefinedContent={setRefinedContent}
                />
            }
        </Flexbox>
    );
};
