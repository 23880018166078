import { DatePicker, Dialog, Flexbox, HorizontalSeparator, Input, Loader, RichTextEditor, Select, Snackbar, TextArea } from 'components';
import { exportToJira } from '../../index.api';
import { ChangeEvent, useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { Initiative, JiraAssignableUserType, JiraFieldAllowedValue, JiraIssueType, JiraProject, JiraProjectIssueTypes, PreferencesKeys, Story } from 'utils/types';

import styles from './jiraExportPopup.module.scss';
import classNames from 'classnames/bind';
import { getJiraAssignableUsers, getJiraAutocomplete, getJiraProjectFields, getJiraProjects } from 'common/jira/index.api';
import { getInitiativeById } from 'pages/Initiatives/initiatives.api';
import { useParams } from 'react-router-dom';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import EmptyMapping from '../initiativeExportPopup/components/emptyMapping';
const classes = classNames.bind(styles);


export interface JiraExportPopupProps {
    open: boolean;
    onClose: () => void;
    baseUrl: string;
    story: Story | null | undefined;
    onExport: (story: Story) => void;
}
type JiraFieldsReducerAction = {
    type: 'update';
    key: string;
    payload: any;
} | { type: 'reset' };

interface JiraFieldsReducerState {
    [key: string]: any;
}

interface JiraFieldError {
    [key: string]: string;
}

const jiraFieldsToOmit = [
    'issuetype',
    'project',
    'reporter',
    'issuelinks',
    'parent',
    'labels',
]

const jiraFieldsReducer = (state: JiraFieldsReducerState, action: JiraFieldsReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return {};
        default:
            return state;

    }
}

let optionsTimer: NodeJS.Timeout | null = null;

const JiraExportPopup = ({ open, onClose, baseUrl, story, onExport }: JiraExportPopupProps) => {
    const dispatch = useDispatch();
    const [allProjects, setAllProjects] = useState<JiraProject[]>([]);
    const [selectedProject, setSelectedProject] = useState<JiraProject | undefined>();

    const [allIssueTypes, setAllIssueTypes] = useState<JiraIssueType[]>([]);
    const [selectedIssueType, setSelectedIssueType] = useState<JiraIssueType | null>(null);
    const [assignableUsers, setAssignableUsers] = useState<JiraAssignableUserType[]>([])

    const [jiraFields, setJiraFields] = useReducer(jiraFieldsReducer, {});

    const [loading, setLoading] = useState(false);

    const [exportError, setExportError] = useState<JiraFieldError>({});
    const [preferences, setPreferences] = useState<Record<string, any>>({ main: {} })

    const loadPreferences = async () => {
        const preferences = await dispatch(getPreferences(PreferencesKeys.initiative))

        if (preferences && Array.isArray(preferences)) {
            setPreferences(preferences[0].value)
        }
    }

    useEffect(() => {
        if (open) {
            setSelectedIssueType(null);
            setJiraFields({ type: 'reset' })
            if (story) {
                setJiraFields({ type: 'update', key: 'summary', payload: story.title })
                setJiraFields({ type: 'update', key: 'description', payload: story.description })
            }
        }
    }, [open])

    useEffect(() => {
        setLoading(true);

        Promise.allSettled([
            getProjectIssueTypes(),
            getAssignableUsersOptions()
        ]).finally(() => setLoading(false))

        setSelectedIssueType(null);
    }, [selectedProject])

    // will change
    const params = useParams()
    const initiativeId = params['id'] ? parseInt(params['id']) : undefined;

    const [updatedInitiative, setUpdatedInitiative] = useState<Initiative | undefined>()

    useEffect(() => {
        loadInitiative()
    }, [])

    const loadInitiative = async () => {
        if (initiativeId) {
            const initiative: Initiative = (await dispatch(getInitiativeById(initiativeId))) as unknown as Initiative
            setUpdatedInitiative(initiative)
        }
    }
    // End will change

    const getProjectIssueTypes = async () => {
        if (selectedProject) {
            const projectIssueTypes = (await dispatch(getJiraProjectFields(selectedProject.key))) as unknown as JiraProjectIssueTypes;
            setAllIssueTypes(projectIssueTypes.issuetypes);
        }
    }

    const getAssignableUsersOptions = async () => {
        if (selectedProject) {
            const users = (await dispatch(getJiraAssignableUsers(selectedProject.key))) as unknown as JiraAssignableUserType[];
            setAssignableUsers(users)
        }
    }

    const getProjects = async () => {
        const projects = (await dispatch(getJiraProjects())) as unknown as JiraProject[];
        setAllProjects(projects);
    }

    const onIssueTypeSelect = (e: ChangeEvent<{}>, value: JiraIssueType | null) => {
        setSelectedIssueType(value);
        clearExportError();
    }

    const onFieldSelect = (name: string, value: JiraFieldAllowedValue | JiraFieldAllowedValue[] | null) => {
        setJiraFields({ type: 'update', key: name, payload: value })
    }

    const onDescriptionChange = (value: string) => {
        setJiraFields({ type: 'update', key: 'description', payload: value })
    }

    const onFieldValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setJiraFields({ type: 'update', key: name, payload: value })
    }

    const onDateValueChange = (date: Date | [Date | null, Date | null] | null, name: string) => {
        setJiraFields({ type: 'update', key: name, payload: date })
    };

    const onCreateIssue = async () => {
        if (story) {

            const params: { [key: string]: any } = {
                project: {
                    id: selectedProject?.id
                },
                issuetype: {
                    id: selectedIssueType?.id
                },
            }

            Object.entries(jiraFields).forEach(([key, value]) => {
                if (value.id) {
                    value.id += '';
                } else if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].id) {
                            value[i].id += ''
                        }
                    }
                }
                params[key] = value
            })

            if (updatedInitiative?.key && selectedProject && updatedInitiative.key.startsWith(selectedProject.key) && selectedIssueType && ('parent' in selectedIssueType.fields)) {
                params.parent = {
                    key: updatedInitiative?.key
                }
            }
            console.log(params);
            // if(params.priority && params.priority.id) {
            //     params.priority.id += ''
            // }
            try {
                const newStory = (await dispatch(exportToJira(baseUrl, story.id, params))) as unknown as Story;
                onExport(newStory);
            } catch (e: any) {
                if (e.data.errorMessage) {
                    const error = JSON.parse(e.data.errorMessage);
                    setExportError(error)
                }
                // setExportError('Something went wrong!');
            }
        }

    }

    const clearExportError = () => {
        setExportError({});
    }

    const getOptions = async (url: string, fieldId: string, value: string) => {

        if (selectedIssueType) {
            if (optionsTimer) {
                clearTimeout(optionsTimer);
            }
            optionsTimer = setTimeout(async () => {
                const data = await dispatch(getJiraAutocomplete(url, value)) as unknown as any[];
                selectedIssueType.fields[fieldId].options = data;
                setSelectedIssueType({ ...selectedIssueType })
            }, 500)

        }
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            await Promise.all([
                getProjects(),
                loadPreferences()
            ]).finally(() => setLoading(false))
        }

        fetchData();
    }, [])

    useEffect(() => {
        if (!selectedProject) {
            let projectToSelect = null;

            if (updatedInitiative?.key) {
                projectToSelect = allProjects.find(project => updatedInitiative.key?.startsWith(project.key));
            } else if (preferences?.main?.lastSelectedProjectId) {
                projectToSelect = allProjects.find(proj => proj.id === preferences.main.lastSelectedProjectId);
            }

            projectToSelect = projectToSelect || allProjects[0];

            if (projectToSelect) {
                setLoading(true);
                setSelectedProject(projectToSelect);

                if (projectToSelect === allProjects[0]) {
                    setSelectedIssueType(null);
                }
                setLoading(false);
            }
        }
    }, [updatedInitiative, preferences, selectedProject, allProjects]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'Export to Jira'}
            confirmButton
            confirmButtonLabel='Export'
            onConfirm={onCreateIssue}
            size='middle'
        >
            <Flexbox className={classes('jiraExportDialogContainer')} vertical>
                <Flexbox fullWidth vertical>
                    {loading || !allProjects.length || !allIssueTypes.length ? <Flexbox fullWidth align justify><Loader disableShrink /></Flexbox> : (
                        <>
                            <Flexbox className={classes('rowContainer')}>
                                {selectedProject ? (
                                    <Select
                                        onChange={(e: ChangeEvent<{}>, value: JiraProject) => {
                                            setSelectedProject(value)
                                            dispatch(updatePreferences({ ...preferences, main: { ...preferences.main, lastSelectedProjectId: value.id } }, PreferencesKeys.initiative));
                                        }}
                                        value={selectedProject}
                                        options={allProjects}
                                        disableClearable
                                        label="Project"
                                        getOptionLabel={option => `${option.name} (${option.key})`}
                                    />
                                ) : <EmptyMapping />}
                            </Flexbox>
                            <Flexbox className={classes('rowContainer')}>
                                <Select
                                    value={allIssueTypes.find(issueType => selectedIssueType?.id === issueType.id)}
                                    options={allIssueTypes}
                                    getOptionLabel={option => option.name}
                                    onChange={onIssueTypeSelect}
                                    errorText={exportError['issuetype']}
                                    label='Issue type'
                                    disableClearable
                                />
                            </Flexbox>
                            <HorizontalSeparator dashed />
                            {selectedIssueType && Object.values(selectedIssueType.fields).filter(field => !jiraFieldsToOmit.includes(field.key) && (!field.key.includes('customfield_') || field.required)).map((field) => {
                                if (!field.allowedValues && !field.autoCompleteUrl && field.schema.type !== 'array') {

                                    return (
                                        <Flexbox className={classes('rowContainer')}>
                                            {field.key === 'description' ? (
                                                <RichTextEditor
                                                    value={jiraFields[field.key] || ''}
                                                    placeholder={field.name}
                                                    label={field.name}
                                                    required={field.required}
                                                    errorText={exportError[field.key]}
                                                    onChange={onDescriptionChange}
                                                />
                                            ) : field.schema.type === 'date' ? (
                                                <DatePicker
                                                    name={field.key}
                                                    selected={jiraFields[field.key] ? new Date(jiraFields[field.key]) : null}
                                                    onChange={(date) => onDateValueChange(date, field.key)}
                                                    fullWidth
                                                    label={field.name}
                                                />
                                            ) : (
                                                <Input
                                                    value={jiraFields[field.key] || ''}
                                                    fullWidth
                                                    alwaysEditable
                                                    label={field.name}
                                                    required={field.required}
                                                    placeholder={field.name}
                                                    name={field.key}
                                                    onChange={onFieldValueChange}
                                                    errorText={exportError[field.key]}
                                                />
                                            )}
                                        </Flexbox>
                                    )
                                } else if (field.allowedValues) {
                                    return (
                                        <Flexbox className={classes('rowContainer')}>

                                            <Select
                                                value={jiraFields[field.key]}
                                                options={field.allowedValues}
                                                name={field.key}
                                                label={field.name}
                                                required={field.required}
                                                errorText={exportError[field.key]}
                                                multiple={field.schema.type === 'array'}
                                                getOptionLabel={option => option.name || option.value}
                                                onChange={(e: ChangeEvent<{}>, value: JiraFieldAllowedValue | JiraFieldAllowedValue[] | null) => onFieldSelect(field.key, value)}
                                                disableClearable={field.required}
                                            />
                                        </Flexbox>
                                    )
                                } else if (field.key === 'assignee') {
                                    return (
                                        <Flexbox className={classes('rowContainer')}>
                                            <Select
                                                options={assignableUsers}
                                                name={field.key}
                                                label={field.name}
                                                required={field.required}
                                                errorText={exportError[field.key]}
                                                getOptionLabel={option => option.displayName}
                                                onChange={(e: ChangeEvent<{}>, value: any) => onFieldSelect(field.key, value)}
                                            />
                                        </Flexbox>
                                    )
                                } else if (field.autoCompleteUrl) {
                                    return (
                                        <Flexbox className={classes('rowContainer')}>
                                            <Select
                                                name={field.key}
                                                label={field.name}
                                                required={field.required}
                                                errorText={exportError[field.key]}
                                                getOptionLabel={option => option.displayName}
                                                onChange={(e: ChangeEvent<{}>, value: any) => onFieldSelect(field.key, value)}
                                                onInputChange={(e: ChangeEvent<{}>, value: string) => { getOptions(field.autoCompleteUrl || '', field.key, value) }}
                                                options={field.options || []}
                                            />
                                        </Flexbox>
                                    )
                                }
                                return (<Flexbox></Flexbox>)
                            })}
                        </>
                    )}
                </Flexbox>
            </Flexbox>


        </Dialog>
    )
}

export default JiraExportPopup;