import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { Flexbox, ConfirmationDialog, Loader, Snackbar } from 'components';
import styles from './teams.module.scss';
import { Preferences, PreferencesKeys, Product, Team, TeamSaveModel, User } from 'utils/types';
import { AddMembersDialog } from './components';
import { getProducts } from 'pages/Products/products.api';
import { productsSelector } from 'store/products';
import { SaveStatus } from 'common/savingStatus';
import AgGridTable, { AgColumn, ColumnTypes, CustomCellRendererParams, GridStatePreferences } from 'components/AgGridTable';
import { CellEditingStoppedEvent, ColDef, GetDetailRowDataParams, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community';
import SelectEditor from 'components/AgGridTable/components/SelectEditor';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { useSearchParams } from 'react-router-dom';
import { useWorkspaceId } from 'utils/hooks';
import TeamsHeader from './components/TeamsHeader';
import { useCreateNewTeamMutation, useDeleteTeamMutation, useEditTeamMutation, useGetTeamsQuery } from 'store/teams-api';
import { deleteTeamAction, setTeamsData, teamsSelector, updateTeamAction, updateTeamSavingStatus } from 'store/teams-slice';
import { usersSelector } from 'store/users-slice';
import { useLazyGetUsersQuery } from 'store/users-api';
const classes = classNames.bind(styles);

function isEmptyAddCell(params: { add: boolean, value?: any }) {
    return params?.add && !params.value
}

function onCellEditingStopped(params: CellEditingStoppedEvent) {
    if (params.data.add) {
        const oldCols = params.api.getColumnDefs();

        if (oldCols) {
            const newCols = oldCols.map((col: ColDef<any, any>) => {

                const oldData = params.data;
                const field = params.colDef.field;

                const newData = { ...oldData };
                newData[field!] = params.newValue;

                if (col.field === 'actions') {
                    return {
                        ...col,
                        cellRendererParams: {
                            isAddActive: !!newData.name,
                            newRow: newData
                        },
                    }
                } else {
                    return col
                }

            })

            params.api.setGridOption(
                'columnDefs',
                newCols
            );
        }
    }
}

type InputRowType = { add: boolean, name: string, lead: User | null, products: Product[] }

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    teamGridLayout = 'teamGridLayout'
}

interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof User,
    teamGridLayout?: any
}

interface IPreferencesParams extends Omit<IPreferencesData, 'teamGridLayout'> { }

let timeoutId: NodeJS.Timeout;

const Teams = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const preferencesRef = useRef<IPreferencesData>({});
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: teamsData, isLoading: teamsLoading } = useGetTeamsQuery({ workspaceId });
    const [getUsers] = useLazyGetUsersQuery();

    const [editTeam] = useEditTeamMutation();
    const [deleteTeam] = useDeleteTeamMutation();
    const [createNewTeam] = useCreateNewTeamMutation();

    const teams = useSelector(teamsSelector)
    const users = useSelector(usersSelector);
    const products: Product[] = useSelector(productsSelector);

    const teamsColumnDefs: AgColumn[] = useMemo(() => [
        {
            headerName: 'Name',
            field: 'name',
            minWidth: 200,
            sortable: true,
            editable: true,
            valueFormatter: (params) =>
                isEmptyAddCell({ add: params.data?.add, value: params.value })
                    ? 'Name...'
                    : params.value,
            cellClass: params => (!params.value && params?.data?.add) ? 'ag-placeholder' : '',
            cellRendererSelector: (params: ICellRendererParams) => {
                const hasGrouping = params.api.getRowGroupColumns().length > 0
                if (hasGrouping) {
                    return { component: undefined }
                } else {
                    return { component: 'agGroupCellRenderer' }
                }
            },
            cellRendererParams: {
                suppressCount: true,
                suppressDoubleClickExpand: true,
                suppressEnterExpand: true,
            },
        },
        {
            headerName: 'Lead',
            field: 'lead',
            minWidth: 220,
            sortable: true,
            editable: true,
            cellEditorPopup: true,
            cellEditor: SelectEditor,
            cellEditorParams: {
                options: users,
                placeholder: 'Select lead',
                selectorName: 'fullName',
                disableClearable: true
            },
            valueFormatter: (params) => {
                return isEmptyAddCell({ add: params?.data?.add, value: params.value })
                    ? 'Lead...'
                    : !!params.value ? params.value.fullName ?? '' : ''
            },
            cellClass: params => (!params.value && params?.data?.add) ? 'ag-placeholder' : '',
            valueParser: params => params
        },
        {
            headerName: 'Products',
            field: 'products',
            minWidth: 400,
            sortable: true,
            editable: true,
            cellEditorPopup: true,
            cellEditor: SelectEditor,
            cellEditorParams: {
                options: products,
                placeholder: 'Select product',
                selectorName: 'title',
                multiple: true,
                disableClearable: true
            },
            wrapText: true,
            autoHeight: true,
            cellClass: params => (!params.value?.length && params?.data?.add) ? 'ag-custom-cell ag-placeholder' : 'ag-custom-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const isGrouped = params.node.group;
                const isPivoted = params.node.leafGroup;

                if (isEmptyAddCell({ add: params.data?.add, value: params.value?.length })) {
                    return 'Products...'
                } else if (isGrouped || isPivoted) {
                    if (Array.isArray(params.value) && params.value.length > 0) {
                        return params.value.map(el => el.title).join(', ')
                    } else if (params.value?.length === 0) {
                        return '(Blanks)'
                    } else {
                        return ''
                    }
                } else {
                    return (
                        <Flexbox wrap className='itemBox'>
                            {
                                Array.isArray(params?.data?.products) && params.data?.products.map((el: any) => <Flexbox className={'chipItem'} key={`${el.id}-${el.name}`}>{el.title}</Flexbox>)
                            }
                        </Flexbox>
                    );
                }
            },
            valueFormatter: (params) =>
                isEmptyAddCell({ add: params.data?.add, value: !!params.value?.length })
                    ? 'Products...'
                    : params.value?.map((el: { title: string, id: number }) => el.title).join(', '),
            valueParser: params => params
        },
        {
            colType: ColumnTypes.Action,
            field: 'actions',
            headerName: '',
            cellRendererParams: {
                isAddActive: false,
            },
            actions: params => {
                if (params?.node?.rowPinned !== 'top') {
                    return [
                        {
                            label: 'Add member',
                            action: () => { showAddMembers(params.data) }
                        },
                        {
                            action: () => { showDeleteConfirmation(params.data?.id) },
                            label: 'Delete',
                            type: 'red'
                        }
                    ]
                } else {
                    return []
                }
            },
        },
    ], [users, products]);

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                columnDefs: [
                    {
                        headerName: 'Name',
                        field: 'fullName',
                        minWidth: 200,
                        flex: 1
                    },
                    {
                        headerName: 'Title',
                        field: 'jobTitle',
                        minWidth: 200,
                        flex: 1
                    },
                    {
                        headerName: 'Email',
                        field: 'email',
                        minWidth: 200,
                        flex: 1
                    },
                    {
                        colType: ColumnTypes.Action,
                        field: 'actions',
                        headerName: '',
                        actions: params => {
                            return [
                                {
                                    action: () => {
                                        const teamId = params.context.masterGrid.data.id
                                        const team = teams.find(team => team.id === teamId)

                                        if (team) {
                                            const newTeam = {
                                                ...team,
                                                users: team.users.filter((user: User) => user.id !== params.data?.id)
                                            }
                                            onUpdateTeam(newTeam, true)
                                        }

                                    },
                                    label: 'Remove from team',
                                    type: 'red'
                                }
                            ]
                        },
                        minWidth: 50,
                        flex: 1
                    },
                ] as AgColumn[]
            },
            getDetailRowData: (params: GetDetailRowDataParams) => params.successCallback(params.data?.users),
        }
    }, [teams])

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [currentlyDeleting, setCurrentlyDeleting] = useState<number | null>(null);
    const [currentlyAddingMembers, setCurrentlyAddingMembers] = useState<number | null>(null);
    const [existingMembers, setExistingMembers] = useState<number[]>([]);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [inputRow, setInputRow] = useState<InputRowType>({ add: true, name: '', lead: null, products: [] });

    const [searchValue, setSearchValue] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(true);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.teamGridLayout))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const { teamGridLayout, order, orderBy, query } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            preferencesRef.current = {
                query,
                order,
                orderBy,
                teamGridLayout
            }

            if (searchParams.toString().length === 0) {
                let queryFilters: IPreferencesParams = {}

                queryFilters = {
                    ...queryFilters,
                    ...(order && orderBy ? { order, orderBy } : {}),
                    ...(query ? { query } : {})
                };

                setSearchParams(queryFilters, { replace: true })
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getProducts()),
                getUsers({ workspaceId }),
                loadPreferences()
            ])
            setLoading(false)
        }
        setLoading(true);
        fetchData();
    }, []);

    useEffect(() => {
        if (!loading) {
            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }
        }
    }, [loading]);

    useEffect(() => {
        if (teamsData) {
            const filteredData = teamsData.filter((team: Team) => team.name?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            dispatch(setTeamsData(filteredData));
        }
    }, [debouncedQuery, teamsData]);

    useEffect(() => {
        if (debouncedQuery !== null) {
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.teamGridLayout));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    const showDeleteConfirmation = (id: number) => {
        setCurrentlyDeleting(id);
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setCurrentlyDeleting(null);
        setOpenConfirmation(false)
    }

    const showAddMembers = (team: Team) => {
        setExistingMembers(team.users.map(user => user.id))
        setCurrentlyAddingMembers(team.id);
    }

    const closeAddMembers = () => {
        setCurrentlyAddingMembers(null);
    }

    const onUpdateTeam = async (team: Team & { add?: boolean }, updateState?: boolean) => {
        if (!team.add) {
            dispatch(updateTeamSavingStatus(SaveStatus.SAVING));
            const res: any = await editTeam({
                workspaceId, teamId: team.id, data: {
                    name: team.name,
                    userIds: team.users.map(u => u.id),
                    productIds: team.products.map(p => p.id),
                    lead: team.lead ? team.lead.id : null,
                }
            })

            if (res.error) {
                setErrorMessage(res.error.data.errorMessage)
            } else if (updateState) {
                dispatch(updateTeamAction(team))
            }

            return !res.error
        }
    }

    const onDeleteConfirmation = async () => {
        if (currentlyDeleting) {
            try {
                const res: any = await deleteTeam({ workspaceId, teamId: currentlyDeleting });
                if (res.error) {
                    setErrorMessage(res.error.data.errorMessage)
                } else {
                    dispatch(deleteTeamAction(currentlyDeleting))
                    onCancelDelete();
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onAddMembers = async (teamMembers: User[]) => {
        if (currentlyAddingMembers && teamMembers.length) {
            const team = teamsData.find((user: User) => user.id === currentlyAddingMembers);

            if (team) {
                const membersToAdd = teamMembers.filter(newMember => !team.users.find((user: User) => user.id === newMember.id))
                if (membersToAdd.length) {
                    const newTeam = {
                        ...team,
                        users: [...team.users, ...membersToAdd]
                    }

                    await onUpdateTeam(newTeam, true)
                }
            }
        }
        closeAddMembers();
    }

    const onCreateTeam = async (params: CustomCellRendererParams) => {
        const newRow = params.newRow

        if (newRow.name) {
            const team: TeamSaveModel = {
                lead: newRow.lead ? newRow.lead?.id : null,
                name: newRow.name,
                productIds: newRow.products ? newRow.products.map((product: Product) => product.id) : [],
                userIds: [],
            }

            const res: any = await createNewTeam({ workspaceId, data: team })

            setInputRow(({ add: true, name: '', lead: null, products: [] }));

            if (res.error) {
                setErrorMessage(res.error.data.errorMessage)
            } else {
                const oldCols = params.api.getColumnDefs();

                if (oldCols) {
                    const newCols = oldCols.map((col: ColDef<any, any>) => {
                        if (col.field === 'actions') {
                            return {
                                ...col,
                                cellRendererParams: {
                                    isAddActive: false,
                                    newRow: { add: true, name: '', lead: null, products: [] }
                                },
                            }
                        } else {
                            return col
                        }
                    })

                    params.api.setGridOption(
                        'columnDefs',
                        newCols
                    );
                }
            }
        }
    }

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
            preferencesRef.current = { teamGridLayout: preferencesRef.current.teamGridLayout, ...modifiedSearchParamsObject }
            dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.teamGridLayout));
        }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        preferencesRef.current.teamGridLayout = data;

        dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.teamGridLayout));
    }, [preferencesRef]);

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, []);

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);

    return ((teamsLoading || loading) ? <Flexbox fullWidth align justify><Loader /></Flexbox> :
        <>
            <Flexbox vertical fullWidth className={classes('mainContainer')}>
                <TeamsHeader gridApi={gridApi} searchValue={searchValue} updateQueryPreference={updateQueryPreference} />
                <Flexbox className={classes('tableContainer')}>
                    <AgGridTable
                        data={teams}
                        columns={teamsColumnDefs}
                        masterDetail
                        isRowMaster={(params: Team) => params.users.length > 0}
                        detailCellRendererParams={detailCellRendererParams}
                        pinnedTopRowData={[inputRow]}
                        onAddNewRow={onCreateTeam}
                        onCellEditingStopped={onCellEditingStopped}
                        onGridReady={onGridReady}
                        order={preferencesRef.current.order}
                        orderBy={preferencesRef.current.orderBy}
                        gridStatePreferences={preferencesRef.current.teamGridLayout}
                        onGridStateChanged={onGridStateChanged}
                        onSortChanged={onSortChanged}
                        exportFileName='Teams'
                        readOnlyEdit={true}
                        updateRowCallback={onUpdateTeam}
                        updateAddingRow={setInputRow}
                        getRowId={getRowId}
                    />
                </Flexbox>
            </Flexbox>

            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteConfirmation}
                confirmButtonStyle='danger'
                title='Are you sure'
            >
                <Flexbox>
                    All data will be lost.
                    Are you sure you want to delete the team?
                </Flexbox>
            </ConfirmationDialog>

            <AddMembersDialog
                open={!!currentlyAddingMembers}
                onClose={closeAddMembers}
                onAdd={onAddMembers}
                existingUsers={existingMembers}
            />

            <Snackbar
                type='error'
                open={!!errorMessage}
                onClose={() => setErrorMessage(null)}
            >
                <Flexbox>{errorMessage}</Flexbox>
            </Snackbar>
        </>
    )
}

export default Teams;